import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PaginationModel } from "../models/interfaces/pagination.model";
import { Observable } from "rxjs";
import { IRequestOptions } from "./utils.service";
import { DishModel } from "../models/interfaces/dish.model";
import { CardItem } from "../models/interfaces/card-item.model";

@Injectable({
  providedIn: 'root'
})

export class DishService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }


  /* GET RANDOM DISHES */
  getRandomDishes(pagination: PaginationModel): Observable<DishModel[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GET_RANDOM_DISHES);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('Skip', pagination?.take * (pagination?.page - 1));
    queryParams = queryParams.append('Take', pagination?.take);
    queryParams = queryParams.append('Seed', pagination?.seed);
    let options = { params: queryParams, withCredentials:true } as IRequestOptions;
    return this.http.get<DishModel[]>(endpoint, options);
  }

  /* GET DISH BY ID */
  getDishDetails(id: number): Observable<DishModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GET_DISH_DETAIL);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('id', id);
    let options = { params: queryParams, withCredentials:true } as IRequestOptions;
    return this.http.get<DishModel>(endpoint, options);
  }

  addToFavorites(dishId: number): Observable<any> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_ADD_TO_FAVORITES_DISH);
    let options = { withCredentials:true } as IRequestOptions;
    return this.http.post(endpoint, {
      entityId: dishId
    }, options);
  }


}
